import React from "react";
import { InlineWidget } from "react-calendly";



const Landing = () => (
  
 
    <div className="App">
      <InlineWidget styles={{
                    height: '900px'}}
                    url="https://calendly.com/hatchetpressmail/web-services-consultation" />
    </div>
);
    export default Landing;