
import "../App.css"
const Websites = () => {

    return(
<div className="">
    These are two of my current running websites, excluding this one.<br/><br/>
    <section>
            Portfolio Links:<br/><br/><ul>
           <li> <a target="blank" href='https://www.recipelab.fun/login'>RECIPE LAB</a><br/></li>

           <li> <a target="blank" href=  'https://hatchetpress.net/#/'>HATCHET PRESS</a><br/></li>

           

            </ul>
        </section>
</div>)

};

export default Websites;